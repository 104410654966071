import { useState } from 'react';
import { Link } from 'react-scroll';
import {
  AiOutlineClose,
  AiOutlineMenu,
  AiFillMail,
  AiFillGithub,
  AiFillLinkedin
} from 'react-icons/ai';

import { SiHashnode } from 'react-icons/si';

function Navbar() {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className='flex justify-end  p-4 md:min-w-[768px]  mx-auto  text-white'>
      <ul className='hidden md:flex mx-16 text-xl'>
        <li className='p-4 text-lg uppercase hover:border-b'>
          <Link to='about' spy={true} smooth={true} offset={50} duration={500}>
            About
          </Link>
        </li>
        <li className='p-4 text-lg uppercase hover:border-b'>
          <Link to='skills' spy={true} smooth={true} offset={50} duration={500}>
            Skills
          </Link>
        </li>
        <li className='p-4 text-lg uppercase hover:border-b'>
          <Link
            to='projects'
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            Projects
          </Link>
        </li>
      </ul>
      <div onClick={handleNav} className='block md:hidden z-10'>
        {nav ? <AiOutlineClose size={24} /> : <AiOutlineMenu size={24} />}
      </div>

      <div
        className={
          nav ? 'md:hidden fixed left-0 top-0 w-full h-screen bg-black/70 ' : ''
        }
      >
        <div
          className={
            nav
              ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#012840] ease-in-out duration-500'
              : 'ease-in-out duration-500 fixed left-[-100%]'
          }
        >
          <div>
            <div className='flex w-full items-center justify-between z-50'>
              <div className='border-b border-white-600 my-16 mx-4'>
                <p className='w-[100%] md:w-[90%] py-4'>
                  Let's build something together.
                </p>
              </div>
            </div>
          </div>
          <div className='py-4 flex flex-col'>
            <ul className='uppercase mx-4 text-xl'>
              <li className='py-4'>
                {' '}
                <Link
                  onClick={handleNav}
                  to='about'
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  About
                </Link>
              </li>
              <li className='py-4'>
                {' '}
                <Link
                  onClick={handleNav}
                  to='skills'
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Skills
                </Link>
              </li>
              <li className='py-4'>
                {' '}
                <Link
                  onClick={handleNav}
                  to='projects'
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  Projects
                </Link>
              </li>
            </ul>

            <div className='pt-16 mx-4'>
              <p className='uppercase tracking-widest'>Let's connect</p>
              <div className='mx-0 py-2 flex'>
                <AiFillMail size={25} />
                <p className='mx-2 text-xs py-1'>markgmz92@gmail.com</p>
              </div>
              <div className='mx-0 pt-16 flex justify-end'>
                <div className='px-0 cursor-pointer hover:scale-105'>
                  <a
                    href='https://github.com/markgmz92'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <AiFillGithub size={40} />
                  </a>
                </div>
                <div className='px-1 cursor-pointer hover:scale-105'>
                  <a
                    href='https://linkedin.com/in/markgmz'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <AiFillLinkedin size={40} />
                  </a>
                </div>
                <div className='px-2 cursor-pointer hover:scale-105'>
                  <a
                    href='https://markgmz.hashnode.dev/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <SiHashnode size={40} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
