import React from 'react';

import LAM from '../assets/LAM.png';
import UVC from '../assets/UVC.png';
import BCS from '../assets/BCS.png';

function Projects() {
  return (
    <div className='w-full' id='projects'>
      <div className='max-w-[1240px] mx-auto px-2 py-16'>
        <h1 className='mx-8 mb-4 uppercase  tracking-widest text-[white] text-2xl'>
          Projects
        </h1>

        <h2 className='py-4 mx-8 text-white text-xl'>
          Projects that I have completed.
        </h2>
        <div className='mx-8 md:mx-8 grid md:grid-cols-2 gap-8'>
          <div className='text-xl py-4 mx-0 text-white'>
            Lumos Arts Media
            <div className='justify-center lg:flex'>
              <img
                className='lg:w-[64vh] py-2 mx-0 shadow-lg rounded-xl'
                src={LAM}
                alt=''
              />
            </div>
            <div className='flex py-1'>
              <div className='bg-[#F5F5F5] w-20 text-center my-2 text-black text-xs font-bold rounded-full p-1'>
                CSS
              </div>
              <div className='bg-[#F5F5F5] mx-2 w-20 text-center my-2 text-black text-xs font-bold rounded-full p-1'>
                JavaScript
              </div>
              <div className='bg-[#F5F5F5] w-20 text-center my-2 text-black text-xs font-bold rounded-full p-1'>
                React
              </div>
            </div>
            <div className='mt-4 mb-2'>
              <p className='leading-relaxed w-[96%] lg:w-[80%]'>
                Lumos Arts Media is a digital agency that is based in Kuala
                Lumpur that specialises in digital marketing and web
                development.{' '}
              </p>
            </div>
            <button className='bg-black w-24 rounded-full py-2 mx-0 my-2 px-3 uppercase text-sm  cursor-pointer tracking-wider'>
              <a
                href='https://lumosartsmedia.com'
                target='_blank'
                rel='noreferrer'
              >
                Visit Site
              </a>
            </button>
          </div>
          <div className='text-xl py-4 mx-0 text-white'>
            Unique Vision Creations
            <div className='justify-center lg:flex'>
              <img
                className='lg:w-[64vh] py-2 mx-0 shadow-lg rounded-xl'
                src={UVC}
                alt=''
              />
            </div>
            <div className='flex py-1'>
              <div className='bg-[#F5F5F5] w-20 text-center my-2 text-black text-xs font-bold rounded-full p-1'>
                CSS
              </div>
              <div className='bg-[#F5F5F5] mx-2 w-20 text-center my-2 text-black text-xs font-bold rounded-full p-1'>
                JavaScript
              </div>
              <div className='bg-[#F5F5F5] w-20 text-center my-2 text-black text-xs font-bold rounded-full p-1'>
                React
              </div>
            </div>
            <div className='mt-4 mb-2'>
              <p className='leading-relaxed w-[96%] lg:w-[100%]'>
                Unique Vision Creations (UVC) is a media agency that specialises
                in mass media and creative work. The agency's scope of work
                includes content creation, TV and radio planning.{' '}
              </p>
            </div>
            <button className='bg-black w-24 rounded-full py-2 mx-0 my-2 px-3 uppercase text-sm  cursor-pointer tracking-wider'>
              <a
                href='https://uniquevisioncreations.com'
                target='_blank'
                rel='noreferrer'
              >
                Visit Site
              </a>
            </button>
          </div>
          <div className='text-xl py-4 mx-0 text-white'>
            Berniece Care Service
            <div className='justify-center lg:block'>
              <img
                className='lg:w-[64vh] py-2 mx-0 shadow-lg rounded-xl'
                src={BCS}
                alt=''
              />
            </div>
            <div className='flex py-1'>
              <div className='bg-[#F5F5F5] w-20 text-center my-2 text-black text-xs font-bold rounded-full p-1'>
                WordPress
              </div>
              <div className='bg-[#F5F5F5] mx-2 w-20 text-center my-2 text-black text-xs font-bold rounded-full p-1'>
                Elementor
              </div>
            </div>
            <div className='mt-4 mb-2'>
              <p className='leading-relaxed w-[96%] lg:w-[100%]'>
                Berniece Care Service is a care home for senior citizens. They
                provide high-quality care to senior residents. Services include
                day care service, 24 hour housing care and hairdressing.
              </p>
            </div>
            <button className='bg-black w-24 rounded-full py-2 mx-0 my-2 px-3 uppercase text-sm  cursor-pointer tracking-wider'>
              <a
                href='https://berniececareservice.com'
                target='_blank'
                rel='noreferrer'
              >
                Visit Site
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
