import React from 'react';
import Typed from 'react-typed';

import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { SiHashnode } from 'react-icons/si';

function Hero() {
  return (
    <div className='text-[#F8F8F8]'>
      <div className='py-56 md:py-32 lg:py-16 px-8 md:mt-[320px] md:px-16 lg:px-24   mx-auto flex flex-col justify-start'>
        <h4 className='text-xl md:text-2xl'>Hello world,</h4>
        <div className='py-2 flex flex-row md:flex '>
          <h2 className='mx-0 py-1 text-2xl justify-start md:justify-start md:text-4xl md:py-2'>
            my name is
          </h2>
          <Typed
            className='py-1 pl-2 z-[-1] text-2xl flex-row md:flex md:py-2 md:text-4xl  lg:py-2 lg:text-4xl font-bold md:pl-4'
            strings={['Mark.']}
            typeSpeed={100}
            backSpeed={120}
            loop
          />
        </div>
        <h3 className='leading-relaxed py-4  text-xl md:flex  md: justify-start  md:py-2 md:text-2xl'>
          I am a web developer based in Kuala Lumpur, MY.
        </h3>
        <h3 className='leading-relaxed text-xl flex justify-start w-[88%] md:py-4 md:text-xl'>
          Checkout my work to see what I can do for you.
        </h3>

        <div className='mt-12 flex mx-0  md:py-4 md:mt-0'>
          <div className='rounded-full hover:scale-105'>
            <a
              href='https://github.com/markgmz92'
              target='_blank'
              rel='noreferrer'
            >
              <FaGithub size={40} />
            </a>
          </div>
          <div className='px-2 rounded-full hover:scale-105'>
            <a
              href='https://linkedin.com/in/markgmz'
              target='_blank'
              rel='noreferrer'
            >
              <FaLinkedin size={40} />
            </a>
          </div>
          <div className='px-1 rounded-full hover:scale-105'>
            <a
              href='https://markgmz.hashnode.dev/'
              target='_blank'
              rel='noreferrer'
            >
              <SiHashnode size={40} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
