import Picture from '../assets/Picture.png';
import { Link } from 'react-scroll';

function About() {
  return (
    <div
      className='py-24  md:py-32 w-full md:h-screen flex items-center'
      id='about'
    >
      <div className='max-w-[1240px] m-auto md:grid grid-cols-3 gap-8'>
        <div className='col-span-2 text-white'>
          <h1 className='mx-8 mb-4 uppercase  tracking-widest text-[white] text-2xl'>
            About
          </h1>

          <p className='w-[88%] py-4 mx-8  text-white text-xl md:w-[80%]   leading-relaxed'>
            Ever since I was young, I had a passion for computers and logic. My
            first experience of web development came when I started tinkering
            with the HTML script of my Friendster page. In university, while
            studying mechanical engineering, I took C++ for a semester and I
            completely fell in love with programming. To me, web development is{' '}
            {''}
            <span className='text-2xl font-bold text-yellow-600'>
              part art and part science.
            </span>
          </p>
          <p className='w-[88%] py-4 mx-8 text-white text-xl md:w-[80%] leading-relaxed'>
            I love problem solving, be it big or small. Programming gives me the
            opportunity to solve problems via code. {''} I am focused on
            building solutions that are fast, scalable, user-friendly, and
            dynamic.
          </p>

          <button className='bg-black rounded-lg hover:bg-white hover:text-black hover:scale-110 mx-8 my-4 p-4'>
            <Link
              to='projects'
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              View Projects
            </Link>
          </button>
        </div>
        <div className='w-full h-full m-auto  flex items-center justify-center p-4'>
          <img src={Picture} alt='/'></img>
        </div>
      </div>
    </div>
  );
}

export default About;
