import React from 'react';

import { AiFillGithub, AiFillLinkedin, AiFillMail } from 'react-icons/ai';
import { SiHashnode } from 'react-icons/si';

function Footer() {
  return (
    <div className='hidden md:w-full md:h-20 md:flex justify-center'>
      <div className='flex'>
        <div className='mt-12 flex mx-0 justify-center md:py-4 md:mt-0'>
          <div className='mx-2'>
            <a
              href='https://github.com/markgmz92'
              target='_blank'
              rel='noreferrer'
            >
              <AiFillGithub color='white' size={40} />
            </a>
          </div>
          <div className='mx-2'>
            <a
              href='https://linkedin.com/in/markgmz'
              target='_blank'
              rel='noreferrer'
            >
              <AiFillLinkedin color='white' size={40} />
            </a>
          </div>
          <div className='mx-2'>
            <a
              href='https://markgmz.hashnode.dev/'
              target='_blank'
              rel='noreferrer'
            >
              <SiHashnode color='white' size={40} />
            </a>
          </div>
          <div className='flex mx-2'>
            <AiFillMail color='white' size={40} />
            <div className='text-white tex-center py-2 mx-2'>
              markgmz@gmail.com
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
