import HTML from '../assets/icons/html.png';
import JS from '../assets/icons/js.png';
import CSS from '../assets/icons/css.png';
import React from '../assets/icons/react.png';
import Tailwind from '../assets/icons/tailwind1.png';
import Node from '../assets/icons/node1.png';
import Express from '../assets/icons/express.png';
import Mongo from '../assets/icons/mongo.png';
import GraphQL from '../assets/icons/graphQL1.png';
import Git from '../assets/icons/git.png';
import WordPress from '../assets/icons/wordpress.png';
import XD from '../assets/icons/xd.png';

function Skills() {
  return (
    <div className='w-full lg:h-screen p-2' id='skills'>
      <div className='max-w-[1240px] mx-auto flex flex-col justify-center h-full'>
        <h1 className='mx-8 mb-4 uppercase  tracking-widest text-[white] text-2xl'>
          Skills
        </h1>
        <h2 className='py-4 mx-8 text-white text-xl'>
          Technologies that I've worked with
        </h2>
        <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-8 mx-8'>
          <div className='p-6 shadow-xl rounded-xl hover:scale-105 ease-in duration-300'>
            <div className='grid grid-cols-2 gap-4 justify-center items-center'>
              <div className='m-auto'>
                <img src={HTML} alt='' />
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3 className='text-white text-xl'>HTML</h3>
              </div>
            </div>
          </div>
          <div className='p-6 shadow-xl rounded-xl hover:scale-105 ease-in duration-300'>
            <div className='grid grid-cols-2 gap-4 justify-center items-center'>
              <div className='m-auto'>
                <img src={JS} alt='' />
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3 className='text-white text-xl'>JavaScript</h3>
              </div>
            </div>
          </div>
          <div className='p-6 shadow-xl rounded-xl hover:scale-105 ease-in duration-300'>
            <div className='grid grid-cols-2 gap-4 justify-center items-center'>
              <div className='m-auto'>
                <img src={CSS} alt='' />
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3 className='text-white text-xl'>CSS</h3>
              </div>
            </div>
          </div>
          <div className='p-6 shadow-xl rounded-xl hover:scale-105 ease-in duration-300'>
            <div className='grid grid-cols-2 gap-4 justify-center items-center'>
              <div className='m-auto'>
                <img src={React} alt='' />
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3 className='text-white text-xl'>React</h3>
              </div>
            </div>
          </div>
          <div className='p-6 shadow-xl rounded-xl hover:scale-105 ease-in duration-300'>
            <div className='grid grid-cols-2 gap-4 justify-center items-center'>
              <div className='m-auto'>
                <img src={Tailwind} alt='' />
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3 className='text-white text-xl'>Tailwind</h3>
              </div>
            </div>
          </div>
          <div className='p-6 shadow-xl rounded-xl hover:scale-105 ease-in duration-300'>
            <div className='grid grid-cols-2 gap-4 justify-center items-center'>
              <div className='m-auto'>
                <img src={Node} alt='' />
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3 className='text-white text-xl'>Node</h3>
              </div>
            </div>
          </div>
          <div className='p-6 shadow-xl rounded-xl hover:scale-105 ease-in duration-300'>
            <div className='grid grid-cols-2 gap-4 justify-center items-center'>
              <div className='m-auto'>
                <img className='py-4' src={Express} alt='' />
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3 className='text-white text-xl'>Express</h3>
              </div>
            </div>
          </div>
          <div className='p-6 shadow-xl rounded-xl hover:scale-105 ease-in duration-300'>
            <div className='grid grid-cols-2 gap-4 justify-center items-center'>
              <div className='m-auto'>
                <img src={GraphQL} alt='' />
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3 className='text-white text-xl'>GraphQL</h3>
              </div>
            </div>
          </div>
          <div className='p-6 shadow-xl rounded-xl hover:scale-105 ease-in duration-300'>
            <div className='grid grid-cols-2 gap-4 justify-center items-center'>
              <div className='m-auto'>
                <img src={Mongo} alt='' />
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3 className='text-white text-xl'>Mongo</h3>
              </div>
            </div>
          </div>
          <div className='p-6 shadow-xl rounded-xl hover:scale-105 ease-in duration-300'>
            <div className='grid grid-cols-2 gap-4 justify-center items-center'>
              <div className='m-auto'>
                <img src={Git} alt='' />
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3 className='text-white text-xl'>Git</h3>
              </div>
            </div>
          </div>
          <div className='p-6 shadow-xl rounded-xl hover:scale-105 ease-in duration-300'>
            <div className='grid grid-cols-2 gap-4 justify-center items-center'>
              <div className='m-auto'>
                <img src={WordPress} alt='' />
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3 className='text-white text-xl'>WordPress</h3>
              </div>
            </div>
          </div>
          <div className='p-6 shadow-xl rounded-xl hover:scale-105 ease-in duration-300'>
            <div className='grid grid-cols-2 gap-4 justify-center items-center'>
              <div className='m-auto'>
                <img src={XD} alt='' />
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3 className='text-white text-xl'>Adobe XD</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skills;
